import React from "react";
import {
  SiteNavigationElementSchema,
  LocalBusinessSchema,
  SocialMedia,
  HookComponentProvider,
  HoursOfOperation,
  LocationsContext,
  NavigationV2,
  PopupAccordionMenuLink,
  FishermanIcon,
  Phone,
  RecaptchaProvider,
  TimedElement,
  FishermanModal,
  Announcement,
  Map,
  Email,
  SiteMap,
  Subscribe,
  createContact,
  CopyrightLabel,
  OutboundLink,
  FishermanBanner,
} from "@bluefin/components";
import { useContext } from "react";
import { Header, Button, Grid, Popup, Icon, Segment } from "semantic-ui-react";
import { graphql, StaticQuery } from "gatsby";

import {
  getComponentFiles,
  joinParentWithChildPages,
  filterRegularLinks,
  filterButtonLinks,
  createImages,
  getParentGeneratedChildPages,
} from "../utils/utils";
import "../semantic/semantic.less";
import "../style/WebsiteLayout.css";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFishermanBusinessWebsitePage(
          sort: { order: ASC, fields: navigationPriority }
        ) {
          nodes {
            slug
            description
            url
            pageType
            title
            navigationPriority
            groupName
            props
            _id
          }
        }
        fishermanBusiness {
          name
          logo
          menu {
            schedules {
              availableDays
              categories
              _id
              name
            }
            categories {
              items
              name
              _id
            }
            items {
              modifierSets
              description
              _id
              name
              variations {
                _id
                menuItem
                name
                order
                price
              }
            }
            modifierSets {
              _id
              minAllowed
              modifiers
              name
            }
            modifiers {
              _id
              name
              price
            }
          }
          socialMedia {
            link
            type
          }
          gatsbyLogo {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, height: 80)
            }
          }
          primaryLocation {
            timezone
            _id
          }
          _id
          type
          brandIdentity
        }
        allFishermanBusinessCategory {
          nodes {
            category
            subCategory
          }
        }
        allFishermanBusinessLocation {
          nodes {
            street
            city
            state
            zipCode
            country
            email
            phoneNumber
            name
            hours {
              open
              close
              day
            }
          }
        }
        fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
          components {
            fastId
            componentIdentifier
            order
            files {
              file
            }
          }
        }
        allSitePage {
          nodes {
            path
            context {
              title
              fishermanParentPageId
              tagName
              fishermanParentPageType
              navigationOrder
              navigationGroup
              navigationGroupOrder
            }
          }
        }
        announcementComponent: fishermanWebsiteComponent(
          layout: { name: { eq: "@" } }
          fastId: { eq: "Announcement" }
        ) {
          data {
            delay
            referenceId
            imagePosition
          }
          files {
            _id
            altText
            file
            gatsbyFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          contentNodes {
            _id
            content
            contentType
          }
          interactions {
            _id
            order
            behaviorType
            displayType
            enabled
            label
            style
            url
          }
        }
        footerRegularPages: allFishermanBusinessWebsitePage(
          filter: { pageType: { ne: "Services" } }
        ) {
          nodes {
            _id
            title
            slug
            pageType
            url
            navigationPriority
            props
          }
        }
      }
    `}
    render={(data) => {
      const {
        allFishermanBusinessWebsitePage,
        fishermanBusiness,
        allFishermanBusinessCategory,
        allFishermanBusinessLocation,
        fishermanBusinessWebsitePage,
        allSitePage,
        announcementComponent,
        footerRegularPages,
      } = data;

      return (
        <React.Fragment>
          <SiteNavigationElementSchema
            pages={allFishermanBusinessWebsitePage.nodes}
            siteUrl={"https://www.salonmagic-bridgeville.com"}
          />
          <LocalBusinessSchema
            name={fishermanBusiness.name}
            categories={allFishermanBusinessCategory.nodes}
            url={"https://www.salonmagic-bridgeville.com"}
            logo={fishermanBusiness.logo}
            address={allFishermanBusinessLocation.nodes}
            menu={fishermanBusiness.menu}
            images={getComponentFiles({
              components: fishermanBusinessWebsitePage.components,
              componentName: "HeroElement",
              numToSelect: 1,
            })}
          />
          <div className={"site-container"}>
            <React.Fragment>
              <div className={"site-cta-header"}>
                <div>
                  <SocialMedia
                    socialMediaValues={fishermanBusiness.socialMedia}
                    buttonType={"rect"}
                    groupButtons={false}
                    showLabels={false}
                  />
                </div>
                <div>
                  <HookComponentProvider
                    hook={useContext}
                    component={
                      <HoursOfOperation
                        displayOption={"dailyWithPopup"}
                        displayOpenClosedStatus={true}
                        enhancedLabels={{
                          openingSoon: "Opening soon",
                          open: "Open now",
                        }}
                      />
                    }
                    hookToPropsMap={{
                      hours: "activeLocation.hours",
                      timezone: "activeLocation.timezone",
                    }}
                    hookParams={[LocationsContext]}
                    onlyRenderOnClientSide={true}
                  />
                </div>
              </div>
              <NavigationV2
                sticky={true}
                fullScreen={true}
                desktop={
                  <React.Fragment>
                    <NavigationV2.Container
                      containerId={"desktop-navigation-bar"}
                    >
                      <NavigationV2.Menu>
                        <NavigationV2.Links
                          links={joinParentWithChildPages({
                            pages: filterRegularLinks({
                              links: allFishermanBusinessWebsitePage.nodes,
                            }),
                            generatedPages: allSitePage.nodes,
                          })}
                          renderChildPages={
                            <PopupAccordionMenuLink
                              targetPositionId={"desktop-navigation-bar"}
                            />
                          }
                        />
                      </NavigationV2.Menu>
                      <NavigationV2.Menu>
                        <NavigationV2.Logo
                          title={<Header>{fishermanBusiness.name}</Header>}
                          src={fishermanBusiness.gatsbyLogo.childImageSharp}
                        />
                      </NavigationV2.Menu>
                      <NavigationV2.Menu>
                        <NavigationV2.Item>
                          <div className={"phone-navigation-container"}>
                            <HookComponentProvider
                              hook={useContext}
                              component={<FishermanIcon iconName={"phone"} />}
                              hookToPropsMap={{
                                phone: "activeLocation.phoneNumber",
                              }}
                              requiredProps={["phone"]}
                              hookParams={[LocationsContext]}
                              onlyRenderOnClientSide={true}
                            />
                            <HookComponentProvider
                              hook={useContext}
                              component={Phone}
                              hookToPropsMap={{
                                phone: "activeLocation.phoneNumber",
                              }}
                              hookParams={[LocationsContext]}
                              onlyRenderOnClientSide={true}
                            />
                            <div className={"phone-label"}>Call to Book</div>
                          </div>
                        </NavigationV2.Item>
                        <NavigationV2.Links
                          links={filterButtonLinks({
                            links: allFishermanBusinessWebsitePage.nodes,
                          })}
                        />
                      </NavigationV2.Menu>
                    </NavigationV2.Container>
                  </React.Fragment>
                }
                mobile={
                  <React.Fragment>
                    <NavigationV2.Container className={"menu-header"}>
                      <NavigationV2.Toggle />
                      <div className={"actions-container"}>
                        <HookComponentProvider
                          hook={useContext}
                          component={
                            <Phone
                              iconName={"phone"}
                              event={{
                                category: "Contact",
                                action: "Call",
                                label: "Navigation",
                              }}
                            />
                          }
                          hookToPropsMap={{
                            phone: "activeLocation.phoneNumber",
                          }}
                          hookParams={[LocationsContext]}
                          requiredProps={["phone"]}
                          onlyRenderOnClientSide={true}
                        />
                        <div className={"phone-navigation-container"}>
                          <div className={"phone-label"}>Call to Book</div>
                        </div>
                      </div>
                      <NavigationV2.Logo
                        title={<Header>{fishermanBusiness.name}</Header>}
                        src={fishermanBusiness.gatsbyLogo.childImageSharp}
                      />
                    </NavigationV2.Container>
                    <NavigationV2.Container
                      tray={true}
                      vertical={true}
                      className={"menu-content"}
                    >
                      <div className={"menu-content-header"}>
                        <NavigationV2.Logo
                          title={<Header>{fishermanBusiness.name}</Header>}
                          src={fishermanBusiness.gatsbyLogo.childImageSharp}
                        />
                        <NavigationV2.Toggle />
                      </div>
                      <NavigationV2.Menu>
                        <NavigationV2.Links
                          links={joinParentWithChildPages({
                            pages: filterRegularLinks({
                              links: allFishermanBusinessWebsitePage.nodes,
                            }),
                            generatedPages: allSitePage.nodes,
                          })}
                        />
                      </NavigationV2.Menu>
                      <NavigationV2.Menu>
                        <NavigationV2.Item>
                          <div className={"phone-navigation-container"}>
                            <HookComponentProvider
                              hook={useContext}
                              component={<FishermanIcon iconName={"phone"} />}
                              hookToPropsMap={{
                                phone: "activeLocation.phoneNumber",
                              }}
                              requiredProps={["phone"]}
                              hookParams={[LocationsContext]}
                              onlyRenderOnClientSide={true}
                            />
                            <HookComponentProvider
                              hook={useContext}
                              component={Phone}
                              hookToPropsMap={{
                                phone: "activeLocation.phoneNumber",
                              }}
                              hookParams={[LocationsContext]}
                              onlyRenderOnClientSide={true}
                            />
                            <div className={"phone-label"}>Call to Book</div>
                          </div>
                        </NavigationV2.Item>
                        <NavigationV2.Links
                          links={filterButtonLinks({
                            links: allFishermanBusinessWebsitePage.nodes,
                          })}
                        />
                        <NavigationV2.Item>
                          <SocialMedia
                            socialMediaValues={fishermanBusiness.socialMedia}
                            buttonType={"circle"}
                            colors={"primary"}
                            groupButtons={false}
                            groupVertically={false}
                            showLabels={false}
                          />
                        </NavigationV2.Item>
                      </NavigationV2.Menu>
                    </NavigationV2.Container>
                  </React.Fragment>
                }
              />
              <RecaptchaProvider>
                <TimedElement
                  delay={announcementComponent.data.delay}
                  referenceId={announcementComponent.data.referenceId}
                  timezone={fishermanBusiness.primaryLocation.timezone}
                >
                  <TimedElement.Element>
                    <TimedElement.ActionComponent
                      passThroughActionProp={"onClose"}
                      action={"hide"}
                    >
                      <FishermanModal
                        className={"announcement-modal"}
                        dimmer={"inverted"}
                        defaultOpen={true}
                        closeIcon={
                          <Button icon={true}>
                            <FishermanIcon iconName={"close"} />
                          </Button>
                        }
                      >
                        <FishermanModal.Content>
                          <Announcement
                            images={createImages({
                              images: announcementComponent.files,
                            })}
                            contentNodes={announcementComponent.contentNodes}
                            interactions={announcementComponent.interactions}
                            imagePosition={
                              announcementComponent.data.imagePosition
                            }
                            businessId={fishermanBusiness._id}
                            businessType={fishermanBusiness.type}
                            locationId={fishermanBusiness.primaryLocation._id}
                          />
                        </FishermanModal.Content>
                      </FishermanModal>
                    </TimedElement.ActionComponent>
                  </TimedElement.Element>
                </TimedElement>
              </RecaptchaProvider>
            </React.Fragment>
            <RecaptchaProvider>
              <div className={"page-container"}>{children}</div>
              <footer>
                <Grid stackable={true}>
                  <Grid.Row className={"main-row"}>
                    <Grid.Column width={6} className={"map-column"}>
                      <HookComponentProvider
                        hook={useContext}
                        component={
                          <Map businessName={fishermanBusiness.name} />
                        }
                        hookToPropsMap={{
                          "address.street": "activeLocation.street",
                          "address.city": "activeLocation.city",
                          "address.state": "activeLocation.state",
                          "address.zip": "activeLocation.zipCode",
                        }}
                        hookParams={[LocationsContext]}
                        onlyRenderOnClientSide={true}
                      />
                    </Grid.Column>
                    <Grid.Column width={4} className={"contact-info-column"}>
                      <div className={"contact-info-details"}>
                        <HookComponentProvider
                          hook={useContext}
                          component={
                            <div>
                              <strong>Contact</strong>
                            </div>
                          }
                          hookToPropsMap={{
                            phone: "activeLocation.phoneNumber",
                            email: "activeLocation.email",
                          }}
                          requiredProps={[["phone", "email"]]}
                          hookParams={[LocationsContext]}
                        />
                        <HookComponentProvider
                          hook={useContext}
                          component={Phone}
                          hookToPropsMap={{
                            phone: "activeLocation.phoneNumber",
                          }}
                          hookParams={[LocationsContext]}
                          onlyRenderOnClientSide={true}
                        />
                        <HookComponentProvider
                          hook={useContext}
                          component={Email}
                          hookToPropsMap={{ email: "activeLocation.email" }}
                          hookParams={[LocationsContext]}
                          onlyRenderOnClientSide={true}
                        />
                      </div>
                      <div className={"contact-info-hours"}>
                        <HookComponentProvider
                          hook={useContext}
                          component={
                            <HoursOfOperation
                              displayOption={"dailyWithPopup"}
                              displayOpenClosedStatus={true}
                              enhancedLabels={{
                                openingSoon: "Opening soon",
                                open: "Open now",
                              }}
                              header={
                                <strong className={"hours-footer-header"}>
                                  Hours of Operation
                                </strong>
                              }
                            />
                          }
                          hookToPropsMap={{
                            hours: "activeLocation.hours",
                            timezone: "activeLocation.timezone",
                          }}
                          hookParams={[LocationsContext]}
                          onlyRenderOnClientSide={true}
                        />
                      </div>
                    </Grid.Column>
                    <Grid.Column width={6} className={"sitemap-column"}>
                      <Grid
                        stackable={true}
                        doubling={true}
                        className={"sitemap-grid"}
                      >
                        <Grid.Row className={"sitemap-row"} columns={2}>
                          <Grid.Column className={"website-links"} width={8}>
                            <SiteMap
                              links={footerRegularPages.nodes}
                              horizontal={false}
                              bulleted={false}
                            />
                          </Grid.Column>
                          <Grid.Column
                            className={"service-category-links"}
                            width={8}
                          >
                            <SiteMap
                              links={getParentGeneratedChildPages({
                                pages: allFishermanBusinessWebsitePage.nodes,
                                tagName: "ServiceCategories",
                                parentPageType: "Services",
                                generatedPages: allSitePage.nodes,
                              })}
                              horizontal={false}
                              bulleted={false}
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row className={"subscribe-row"}>
                          <div className={"subscribe-cta-container"}>
                            <Popup
                              trigger={
                                <Button primary={true}>
                                  <Icon name={"mail outline"} />
                                  <span>Join Our Mailing List</span>
                                </Button>
                              }
                              className={"subscribe-popup-container"}
                              on={"click"}
                              basic={true}
                            >
                              <div className={"subscribe-popup-content"}>
                                <Segment textAlign={"center"} basic={true}>
                                  <HookComponentProvider
                                    hook={useContext}
                                    component={
                                      <Subscribe
                                        header={"Join Our Mailing List"}
                                        placeholder={"Enter your email address"}
                                        centered={true}
                                        businessId={fishermanBusiness._id}
                                        businessType={fishermanBusiness.type}
                                        onSubmit={createContact}
                                      />
                                    }
                                    hookToPropsMap={{
                                      locationId: "activeLocation.id",
                                    }}
                                    hookParams={[LocationsContext]}
                                  />
                                </Segment>
                              </div>
                            </Popup>
                          </div>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </footer>
            </RecaptchaProvider>
          </div>
          <CopyrightLabel
            company={fishermanBusiness.name}
            phrase={"All Rights Reserved"}
            privacyPolicyLink={
              <OutboundLink
                to={
                  "https://fisherman-static.s3.amazonaws.com/public/__fisherman/Client+Website+Privacy+Policy.pdf"
                }
                label={"Privacy Policy"}
                eventLabel={"copyright-privacy-policy"}
              />
            }
            brand={fishermanBusiness.brandIdentity}
          />
          <FishermanBanner brand={fishermanBusiness.brandIdentity} />
        </React.Fragment>
      );
    }}
  />
);
